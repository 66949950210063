import { UrlItem } from "@retailtune/types/lib/storelocator";

export function createLanguagSidebarContent(
  languages: UrlItem[],
  urlPrefix: string,
  currentLanguage: string
) {
  return (
    <div id={"rt_sidebar"} class="rt-languages">
      <header class="rt-languages__header">
        <span aria-label="current language">{`${currentLanguage.toUpperCase()}`}</span>
      </header>
      <div class="rt-languages__body">
        <ul class="rt-languages__list">
          {languages.map((lang) => (
            <li
              class={`rt-languages__item ${
                currentLanguage === lang.language
                  ? "rt-languages__item--current"
                  : ""
              }`}
              key={lang.language}
            >
              {/* <a href={`${urlPrefix}${lang.path}`}>{lang.language.toUpperCase()}</a> */}
              <a
                href={`${urlPrefix}/${lang.language.toLowerCase()}${lang.path}`}
              >
                {lang.language.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
