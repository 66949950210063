import { Store } from "@retailtune/types/lib/store";
import { Translations } from "./translations";
import { getStoreOpeningTime } from "@retailtune/utils";

export function createOpeningHoursText(
  lang: string,
  store: Store,
  translations: Translations
) {
  // create UTC time
  const d = new Date();
  const utcTime = d.getTime() + d.getTimezoneOffset() * 60 * 1000;

  // fetch opening time data
  const openingTimeResult = getStoreOpeningTime({
    serverTime: utcTime,
    store,
    format: "24h",
    openingsSeparator: "|",
  });

  // time local to store timezone
  const storeTime = new Date(utcTime + store.hourOffset * 1000);

  // create dayname
  let today = storeTime.toLocaleString(lang, { weekday: "long" });
  today = today.charAt(0).toUpperCase() + today.slice(1);

  let openingTimeBlock: HTMLElement | null = null;
  switch (openingTimeResult.status) {
    case "ok":
      openingTimeBlock = (
        <>
          <span class="rt-dayname">{today}</span>
          <time class="rt-opening-time">{openingTimeResult.text}</time>
        </>
      );
      break;
    case "closed":
      openingTimeBlock = (
        <>
          <span class="rt-dayname">{today}</span>
          <time class="rt-opening-time">{translations.k_store_closed}</time>
        </>
      );
      break;
    case "error":
      console.warn(
        `unable to create opening time text for store ID=${store.id}`
      );
      break;
  }

  if (openingTimeBlock) {
    return <span class="rt-store-card__hours-today">{openingTimeBlock}</span>;
  } else {
    return null;
  }
}
